@import 'libs/uikit/src/styles/global';

.claimed {
  opacity: 0.5;
}

.benefit {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;

  .badge-row {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px;
  }

  .text-row {
    padding: unset;
    margin: unset;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-greyscale-400);

    li {
      font-size: 12px;
    }

    span {
      position: relative;
      left: -6px;
      font-size: 20px;
    }
  }
}
