@import 'libs/uikit/src/styles/global';

.pin-modal {
  width: fit-content;
  min-height: 154px;
  position: relative;
  border-radius: 4px;
  padding: 16px;
  background-color: var(--color-greyscale-100);
  margin-right: auto;
  margin-left: auto;
}

.header {
  font-size: 24px;
  line-height: 24px;
  color: var(--color-greyscale-600);
  font-weight: 700;
  margin: 0px;
  padding: 0px;
}

.sub {
  font-size: 20px;
  line-height: 20px;
  color: var(--color-greyscale-600);
  font-weight: 400;
  margin: 0 0 16px 0;
  padding: 0px;
}

.close-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: rem(18px);
  color: var(--color-greyscale-300) !important;
  font-weight: 300;
  cursor: pointer;
  pointer-events: auto;
}
