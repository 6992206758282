@import 'libs/uikit/src/styles/global';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.container {
  margin-bottom: 40px;

  .header-wrapper {
    padding: 16px;
    background-color: var(--color-greyscale-200);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 224px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px dashed var(--color-greyscale-400);
      padding: 10px;
      margin-top: 0px;

      & > .title {
        margin-bottom: 24px;
        color: var(--color-white);
      }

      .photo-picker-icon {
        color: var(--color-primary-1);
      }
    }
  }

  .form-container {
    margin-bottom: 0px;
    padding: 0px;

    .form {
      padding: 16px;

      @include media('>md') {
        padding: 0px;
      }

      .middle {
        margin: auto;
        margin-top: -3px;
      }

      .row-name {
        display: grid;
        grid-template-columns: 82px auto;

        .name {
          margin-top: -5px;
        }
      }

      :global(.ant-form-item-label) {
        padding-bottom: 0px;
        margin-bottom: -5px;

        label {
          font-size: rem(20px);
          line-height: 20px;
          color: var(--color-greyscale-400);
        }
      }

      :global(.ant-form-item-explain-error) {
        font-size: rem(18px);
      }

      input::placeholder {
        font-size: rem(20px);
      }

      .textarea-container {
        height: 160px;

        .textarea {
          height: 120px;

          &::placeholder {
            font-size: rem(20px);
          }
        }
      }

      .text-countdown {
        position: relative;
        font-size: rem(16px);
        color: var(--color-greyscale-200);
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        margin-top: -20px;
        margin-right: 5px;
      }

      .submit {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: rem(20px);
        font-weight: 500;

        .btn {
          width: fit-content;
          margin: auto;
        }
      }
    }
  }
}

.create-guild-modal {
  .highlight {
    color: var(--color-primary-1);
  }
}
