@import 'libs/uikit/src/styles/global';

.container {
  width: 100%;
  margin-bottom: 64px;

  .panel {
    width: 100%;
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: var(--color-greyscale-500);

    & > *:first-child {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      background: #f0eaff;
    }

    &:global(.ant-collapse-item-active) {
      :global(.ant-collapse-arrow) {
        transform: rotate(0deg) !important;
      }
    }

    :global(.ant-collapse-arrow) {
      transform: rotate(180deg);
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .panel-badge {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 8px;
  }

  .bronze {
    background: $--vip-bronze;
  }

  .silver {
    background: $--vip-silver;
  }

  .gold {
    background: $--vip-gold;
  }

  .diamond {
    background: $--vip-diamond;
  }
}
