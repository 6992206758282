@import 'libs/uikit/src/styles/global';

.btn {
  &:global(.ant-btn) {
    font-size: rem(18px);
    height: 34px;
    font-weight: normal;
  }
}

.modal {
  :global(.ant-modal-header) {
    padding: 20px;
  }

  &.not-found {
    :global(.ant-modal-body) {
      align-items: center;
    }
  }

  :global(.ant-modal-body) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 300px;

    .not-found {
      color: var(--color-greyscale-400);
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-violet-100);
        width: 100%;
        padding: 8px;
        font-size: rem(22px);

        &.disabled {
          background-color: var(--color-greyscale-200);

          .accept-icon {
            color: var(--color-greyscale-400) !important;
          }
        }

        .detail {
          display: flex;
          flex-direction: column;

          .profile {
            display: flex;
            align-items: center;
          }

          .name {
            font-weight: 600;
            margin-left: 5px;
            font-size: rem(24px);
          }
        }

        .action {
          display: flex;
          gap: 8px;
          color: var(--color-greyscale-400);

          .accept-icon {
            color: var(--color-success);
          }
        }
      }
    }
  }
}
