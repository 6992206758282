@import 'libs/uikit/src/styles/global';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #daccf8;
  border-radius: 4px;
  height: 66px;
  width: 66px;
  color: var(--color-primary-1);

  &.selected-image {
    background-color: transparent;
  }

  .dropdown-overlay {
    :global(.ant-dropdown-menu) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
