@import 'libs/uikit/src/styles/global';

.reward {
  display: grid;
  grid-template-columns: 60px 100px auto 60px;
  width: 100%;
  margin: 0 0px 12px;

  @include media('>md') {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &:first-child {
    margin-top: 36px;
    .label {
      display: block;
    }
  }

  & > * {
    position: relative;
    height: 32px;
  }

  .label {
    display: none;
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: var(--color-primary-1);
    margin-bottom: 4px;
    white-space: nowrap;

    *:first-child {
      text-align: left;
    }

    *:last-child {
      text-align: right;
    }
  }

  .level {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .turn-over {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    color: var(--color-greyscale-400);
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }

  .star {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-width: 100px;
  }

  .claim-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    height: 32px;
    width: 60px;

    .receive {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      width: 100%;
    }
  }
}

.claim-success-noti {
  line-height: rem(24px);

  .paragraph {
    margin: unset;
    line-height: rem(24px);
  }
}
