@import 'libs/uikit/src/styles/global';

.container {
  margin-top: 0px !important;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    flex-direction: column;
    width: 100%;
    height: 224px;
    margin-bottom: 16px;
    background-color: var(--color-greyscale-400);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .title {
      color: var(--color-white);
      text-align: center;
      margin-bottom: 24px;
    }

    .tournament-btn {
      height: rem(38px);
      width: 192px;
      font-weight: normal;
      font-size: rem(22px);
      color: var(--color-primary-1);

      &.disabled {
        color: var(--color-greyscale-500);
        background-color: var(--color-greyscale-300);
        cursor: not-allowed;
      }
    }
  }

  .header-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .create-and-input {
    display: grid;
    grid-template-columns: auto 120px;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;

    & > .create-guild-btn {
      height: rem(38px);
    }
  }

  .body {
    @include media('>md') {
      padding: 0px;
    }

    .loading {
      margin: auto;
      margin-top: 100px;
      width: fit-content;
    }

    .list-container {
      display: grid;
      grid-template-columns: 100%;
      gap: 4px;

      .card {
        display: grid;
        grid-template-columns:
          40px
          calc(30% - 2.4px)
          calc(10% - 2.4px)
          calc(10% - 2.4px)
          auto;
        gap: 12px;
        align-items: center;
        background-color: #eaeaea4d;
        border-radius: 4px;
        width: 100%;
        padding: 12px 8px;
        font-weight: 500;
        font-size: rem(20px);
        color: var(--color-greyscale-600);

        @include media('>md') {
          grid-template-columns:
            44px
            calc(40% - 2.4px)
            calc(20% - 2.4px)
            calc(10% - 2.4px)
            auto;
          padding: 16px;
          font-size: rem(24px);
        }

        .logo {
          margin: auto;
          width: 52px;
          height: 52px;
          border-radius: 50%;
        }

        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .register-btn {
          border: 2px solid var(--color-primary-1);
          font-weight: 500;
          font-size: rem(20px);
          box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
          height: 5ch;
          width: 11ch;
          color: #6729ff;
          background-color: transparent;
          margin-left: auto;

          &.applied {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
