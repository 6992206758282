@import 'libs/uikit/src/styles/global';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 66px;
  width: 66px;
  color: var(--color-black);
  background-color: transparent;
  width: fit-content;
  height: fit-content;

  .dropdown-overlay {
    :global(.ant-dropdown-menu) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 10px;
      padding: 10px;
    }
  }
}
