/**
 * Box Shadows
 */
$--box-shadow-1: 0 4px 12px 0 rgba(1, 27, 42, 0.14);
$--box-shadow-2: 0 2px 10px 0 rgba(1, 27, 42, 0.12);
$--box-shadow-3: 0 2px 8px 0 rgba(1, 27, 42, 0.1);
$--box-shadow-4: 0 1px 6px 0 rgba(1, 27, 42, 0.1);

/**
 * Text Shadows
 */
$--text-shadow-1: 0 4px 12px rgba(1, 27, 42, 0.14);
$--text-shadow-2: 0 2px 10px rgba(1, 27, 42, 0.12);
$--text-shadow-3: 0 2px 8px rgba(1, 27, 42, 0.1);
$--text-shadow-4: 0 1px 6px rgba(1, 27, 42, 0.1);

/**
 * Font Sizes
 */
$font-family: 'db-heavent';

$--font-size-base: 24px;
$--font-size-base-desktop: 24px;
$--font-size-base-mobile: $--font-size-base;
$--line-height-base: 24px;

$--font-size-h1: 34px;
$--font-size-h2: 34px;
$--font-size-h3: 34px;
$--font-size-h4: 34px;
$--font-size-h5: 34px;

$--font-size-h1-desktop: 34px;
$--font-size-h2-desktop: 34px;
$--font-size-h3-desktop: 34px;
$--font-size-h4-desktop: 34px;
$--font-size-h5-desktop: 34px;

$--line-height-h1: 34px;
$--line-height-h2: 34px;
$--line-height-h3: 34px;
$--line-height-h4: 34px;
$--line-height-h5: 34px;

$--font-size-h1-mobile: $--font-size-h1;
$--font-size-h2-mobile: $--font-size-h2;
$--font-size-h3-mobile: $--font-size-h3;
$--font-size-h4-mobile: $--font-size-h4;
$--font-size-h5-mobile: $--font-size-h5;

$--font-size-b1: $--font-size-base;
$--font-size-b2: $--font-size-b1 - 2px;
$--font-size-b3: $--font-size-b2 - 2px;

/**
 * Border Radius
 */
$--border-radius-min: 4px;
$--border-radius-1: 8px;
$--border-radius-2: 16px;
$--border-radius-3: 24px;
$--border-radius-4: 32px;
$--border-radius-5: 40px;
$--border-radius-h: 50%;

/**
 * Grid Gutter
 */
$grid-gutter-width: 16px;
$--grid-gutters: (0, 4, 8, 16);

/**
 * Breakpoints
 */
$grid-breakpoints: (
  ss: 0,
  xs: 320px,
  sm: 375px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);

$container-max-widths: (
  sm: 540px - $grid-gutter-width,
  md: 768px - $grid-gutter-width,
  lg: 992px,
  xl: 1076px,
);

/**
 * VIP badge colors
 */

$--vip-bronze: linear-gradient(
  115.39deg,
  #b94520 21.73%,
  #8c3114 65.76%,
  #b94520 84.35%
);
$--vip-silver: linear-gradient(
  148.67deg,
  #a8a8a8 23.45%,
  rgba(69, 69, 69, 0.95) 59.17%,
  #a8a8a8 81.08%
);
$--vip-gold: linear-gradient(
  148.67deg,
  #eb8d00 23.45%,
  rgba(139, 84, 0, 0.95) 59.17%,
  #eb8d00 81.08%
);
$--vip-diamond: linear-gradient(
  148.67deg,
  #491db5 23.45%,
  rgba(43, 17, 107, 0.95) 59.17%,
  #491db5 81.08%
);

/**
 * z-index
 */
$z-index: (
  base: 0,
  header: 999,
  overlay: 1000,
);

:export {
  colorViolet100: var(--color-violet-100);
  colorViolet200: var(--color-violet-200);
  colorViolet300: var(--color-violet-300);
  colorViolet400: var(--color-violet-400);
  colorViolet500: var(--color-violet-500);
  colorViolet600: var(--color-violet-600);
  colorOrange100: var(--color-orange-100);
  colorOrange200: var(--color-orange-200);
  colorOrange300: var(--color-orange-300);
  colorOrange400: var(--color-orange-400);
  colorOrange500: var(--color-orange-500);
  colorOrange600: var(--color-orange-600);
  colorGreyscale100: var(--color-greyscale-100);
  colorGreyscale200: var(--color-greyscale-200);
  colorGreyscale300: var(--color-greyscale-300);
  colorGreyscale400: var(--color-greyscale-400);
  colorGreyscale500: var(--color-greyscale-500);
  colorGreyscale600: var(--color-greyscale-600);
  colorYellow100: var(--color-yellow-100);
  colorYellow200: var(--color-yellow-200);
  colorLightblue100: var(--color-lightblue-100);
  colorLightblue200: var(--color-lightblue-200);
  colorBlue100: var(--color-blue-100);
  colorBlue200: var(--color-blue-200);
  colorWhite: var(--color-white);
  colorWhite200: var(--color-white-200);
  colorBlack: var(--color-black);
  colorRed: var(--color-red);
  colorTransparent: var(--color-transparent);
  colorRed100: var(--color-red-100);
  colorRed200: var(--color-red-200);
  colorGreen100: var(--color-green-100);
  colorGreen200: var(--color-green-200);

  /**
  * Main color
  */
  colorPrimary1: var(--color-violet-400);
  colorPrimary2: var(--color-orange-400);
  colorSuccess: var(--color-green-200);
  colorWarning: var(--color-yellow-200);
  colorDanger: var(--color-red-200);

  /**
 * Box Shadows
 */
  boxShadow1: var(--box-shadow-1);
  boxShadow2: var(--box-shadow-2);
  boxShadow3: var(--box-shadow-3);
  boxShadow4: var(--box-shadow-4);

  /**
 * Text Shadows
 */
  textShadow1: $--text-shadow-1;
  textShadow2: $--text-shadow-2;
  textShadow3: $--text-shadow-3;
  textShadow4: $--text-shadow-4;

  /**
 * Font Sizes
 */
  fontFamily: $font-family;

  fontSizeBase: $--font-size-base;
  fontSizeBaseDesktop: $--font-size-base-desktop;
  fontSizeBaseMobile: $--font-size-base-mobile;
  lineHeightBase: $--line-height-base;

  fontSizeH1: $--font-size-h1;
  fontSizeH2: $--font-size-h2;
  fontSizeH3: $--font-size-h3;
  fontSizeH4: $--font-size-h4;
  fontSizeH5: $--font-size-h5;

  fontSizeH1Desktop: $--font-size-h1-desktop;
  fontSizeH2Desktop: $--font-size-h2-desktop;
  fontSizeH3Desktop: $--font-size-h3-desktop;
  fontSizeH4Desktop: $--font-size-h4-desktop;
  fontSizeH5Desktop: $--font-size-h5-desktop;

  fontSizeH1Mobile: $--font-size-h1-mobile;
  fontSizeH2Mobile: $--font-size-h2-mobile;
  fontSizeH3Mobile: $--font-size-h3-mobile;
  fontSizeH4Mobile: $--font-size-h4-mobile;
  fontSizeH5Mobile: $--font-size-h5-mobile;

  lineHeightH1: $--line-height-h1;
  lineHeightH2: $--line-height-h2;
  lineHeightH3: $--line-height-h3;
  lineHeightH4: $--line-height-h4;
  lineHeightH5: $--line-height-h5;

  fontSizeB1: $--font-size-base;
  fontSizeB2: $--font-size-b1 - 2px;
  fontSizeB3: $--font-size-b2 - 2px;

  /**
 * Border Radius
 */
  borderRadiusMin: $--border-radius-min;
  borderRadius1: $--border-radius-1;
  borderRadius2: $--border-radius-2;
  borderRadius3: $--border-radius-3;
  borderRadius4: $--border-radius-4;
  borderRadius5: $--border-radius-5;
  borderRadiusH: $--border-radius-h;
}
