@import 'libs/uikit/src/styles/global';

.container {
  max-width: 540px;
  margin: auto;
  height: fit-content;

  .form {
    display: flex;
    gap: 16px;

    .detail {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: var(--color-greyscale-200);
      border-radius: 4px;
      width: 100%;
      padding: 12px 10px;

      .title-row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & > .title {
          font-weight: 500;
        }

        & > .copy-btn {
          font-size: rem(16px);
          font-weight: normal;
        }
      }

      .description-row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .edit-icon {
          align-self: flex-end;
          font-size: rem(16px);
        }
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;

    .small {
      font-size: rem(18px);
      height: 34px;
      font-weight: normal;
    }

    .default {
      border: 2px solid var(--color-primary-1);
      color: var(--color-primary-1);
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .card {
      display: grid;
      grid-template-columns: 40px auto 30px;
      gap: 12px;
      align-items: center;
      background-color: #eaeaea4d;
      border-radius: 4px;
      width: 100%;
      padding: 12px 8px;
      padding-bottom: 9px;
      padding-left: 16px;
      font-weight: 500;
      font-size: rem(20px);
      color: var(--color-greyscale-600);

      @include media('>md') {
        grid-template-columns: 40px auto 30px;
        padding: 16px;
        font-size: rem(24px);
        padding-bottom: 9px;
      }

      .profile-avatar-container {
        width: 40px;
        height: 40px;

        .role {
          position: relative;
          top: -18px;
          left: -7px;
          width: 16px;
          height: 16px;
        }

        .avatar {
          margin: auto;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-top: -20px;
        }
      }

      & > .detail {
        display: flex;
        flex-direction: column;
        font-weight: normal;

        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: rem(20px);
          font-weight: 500;
        }

        & > .description {
          font-size: rem(16px);
          color: var(--color-greyscale-400);
        }
      }

      .register-btn {
        border: 2px solid var(--color-primary-1);
        font-weight: 500;
        font-size: rem(20px);
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
        height: 5ch;
        width: 11ch;
        color: #6729ff;
        background-color: transparent;
        margin-left: auto;
      }

      .more-icon {
        transform: rotate(90deg);
      }
    }

    .avatar {
      border-radius: 50%;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 51%;
    z-index: 1;
  }
}

.leave-guild-modal {
  .danger {
    font-size: rem(20px);
    color: var(--color-red);
  }
}
