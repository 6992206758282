@import 'libs/uikit/src/styles/global';

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;
  margin: auto;
  padding: 12px 24px;

  @include media('>md') {
    gap: 80px;
    max-width: fit-content;
  }

  .img {
    max-width: 100%;
    height: fit-content;
    border: 8px solid var(--color-primary-1);
    border-radius: 50%;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eaeaea;
    border-radius: 50%;
    max-width: 100%;
    aspect-ratio: 1;

    @include media('>467px') {
      width: 120px;
    }

    .icon {
      display: flex;
      justify-content: center;
      border: 3px dashed var(--color-greyscale-400);
      width: 90%;
      aspect-ratio: 1;
      border-radius: 50%;
      color: var(--color-greyscale-500);
      font-weight: 700;
      // font-size: 9vmin;
      font-size: rem(46px);
    }
  }
}

.achievement-modal {
  .title {
    font-size: rem(24px);
    line-height: rem(18px);
    text-align: center;
  }

  .description {
    font-size: rem(24px);
  }
}
