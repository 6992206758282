@import 'libs/uikit/src/styles/global';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  .logo {
    display: block;
    margin: rem(16px) auto 0 auto;
    width: 139px;
    height: fit-content;
    margin-bottom: 20px;
  }

  .maintenance-logo {
    text-align: center;
    img {
      margin-top: rem(16px);
      margin-left: 5%;
    }
  }

  .title {
    display: block;
    margin-top: rem(16px);
    font-weight: 400;
    color: var(--color-violet-400);
    text-align: center;
  }

  .content {
    text-align: center;
    line-height: rem(24px);
    padding: 0 rem(12px);

    @include media('>md') {
      padding: 0 16.5%; // Note: 8 grid = (8/12 * 100)/2
    }
    @include media('>lg') {
      padding: 0 25%; // Note: 6 grid = (6/12 * 100)/2
    }
  }
}
