@import 'libs/uikit/src/styles/global';

.container {
  .header {
    display: flex;
    gap: 6px;

    .title {
      font-size: rem(22px);
    }

    .info-icon {
      width: rem(14px);
      color: var(--color-blue-200);
    }
  }

  .achievement {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-direction: row-reverse;
    text-align: center;
    gap: 14px;
    margin: auto;
    padding: 12px 24px;

    @include media('>md') {
      grid-template-columns: repeat(4, 1fr);
      gap: 60px;
      row-gap: 40px;
      max-width: fit-content;
    }

    .unfocused {
      opacity: 0.3;
    }

    .close-icon {
      position: relative;
      z-index: 1;
      float: right;
      margin-left: -30px;
      color: var(--color-greyscale-400);
      font-size: rem(30px);
      background-color: var(--color-white);
      border-radius: 50%;
    }

    .is-highlight {
      position: relative;
      z-index: 1;
      float: right;
      margin-left: -30px;
      color: var(--color-green-200);
      font-size: rem(30px);
      background-color: var(--color-white);
      border-radius: 50%;

      &.select {
        color: var(--color-greyscale-400);
      }
    }

    .progress {
      width: 100%;
      height: 100%;
      max-width: 100px;
      max-height: 100px;
      @include media('>md') {
        max-width: 120px;
        max-height: 120px;
      }

      :global(.ant-progress-inner) {
        width: 100% !important;
        height: 100% !important;
        @include media('>md') {
          width: 120px !important;
          height: 120px !important;
        }

        :global(.ant-progress-circle) {
          position: relative;
          z-index: 1;
        }
      }
    }

    .img {
      max-width: 100%;
      height: fit-content;

      &.completed {
        border: 8px solid var(--color-primary-1);
        border-radius: 50%;
      }

      &.progress {
        filter: grayscale(100%);
        border: none;
      }
    }

    .selected-highlight-container {
      max-width: 100px;
      max-height: 100px;
      width: 100%;
      height: 100%;
      border: 7px solid var(--color-primary-1);
      border-radius: 50%;

      @include media('>md') {
        max-width: 120px;
        max-height: 120px;
      }

      .selected-highlight {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #eaebeb;
        border-radius: 50%;
        border: 3px dashed var(--color-greyscale-300);

        svg {
          fill: var(--color-greyscale-300);
        }
      }
    }

    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eaeaea;
      border-radius: 50%;
      max-width: 100%;
      aspect-ratio: 1;

      @include media('>md') {
        width: 120px;
      }

      .icon {
        display: flex;
        justify-content: center;
        border: 3px dashed var(--color-greyscale-400);
        width: 90%;
        aspect-ratio: 1;
        border-radius: 50%;
        color: var(--color-greyscale-500);
        font-weight: 700;
        // font-size: 9vmin;
        font-size: rem(46px);
      }
    }
  }
}

.modal-image-container {
  :global(.ant-modal-body) {
    padding: 40px;
  }

  .img {
    width: 100px;
    height: 100px;
    border: 8px solid var(--color-primary-1);
    border-radius: 50%;
    margin-top: -100px;

    @include media('>md') {
      width: 120px;
      height: 120px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    font-size: rem(24px);
    line-height: 18px;

    & > h4 {
      line-height: 18px;
      font-weight: bolder;
      font-size: rem(24px);
    }
  }
}
