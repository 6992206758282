@import 'libs/uikit/src/styles/global';

.form {
  :global(.anticon) {
    width: rem(24px);
  }

  :global(.ant-form-item) {
    margin-bottom: 0px;
  }
}
