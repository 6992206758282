// Custom font only this project here

/**
* Base font family
*/
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Li.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Med.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Bd.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'db-heavent';
  src: url('/fonts/DB-Heavent/DB-Heavent-Original/DB-Heavent-Blk.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}
