@import 'libs/uikit/src/styles/global';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin: 24px 0px 0px 0px !important;

  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  h4 {
    width: 100%;
    font-weight: 900;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-greyscale-500);
    margin-bottom: 12px;
  }

  .title-group {
    display: grid;
    width: 100%;
    grid-template-columns: auto 130px;

    & > .benefit {
      font-size: rem(20px);
      color: var(--color-blue-200);
    }
  }

  .exp {
    margin-top: 1rem;
  }

  .divider {
    padding-top: 8px;
    margin-top: 18px;
    border-top: 1px solid rgba(128, 128, 128, 0.1);
  }

  .avatar {
    width: 60px;
  }

  .guild {
    margin-left: 34px;
    margin-top: -22px;
  }

  .benefits {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 8px;
    margin-top: 18px;
  }

  .rewards {
    width: 100%;
  }

  .achievement {
    width: 100%;

    & > .header {
      display: flex;
      justify-content: space-between;

      .right-icon {
        width: rem(20px);
      }
    }
  }
}

.benefit-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 420px;
  height: 90%;
  max-height: 70dvh;
  z-index: 1000;
  padding-left: 12px;
  transform: translate(-50%, -50%);
  user-select: none;
  overflow: hidden;
  z-index: 2000;
  margin-bottom: 30dvh;
  background: white;
  box-shadow: 1px 1px 5px #00000030;
  border-radius: 4px;
  .benefit-list {
    height: calc(100% - 47px);
    overflow-y: scroll;
    pointer-events: all;
    padding: 12px;
    border-radius: 8px;
    max-height: 70dvh;
    ul {
      padding: 0px;
    }
    li {
      text-align: left;
      list-style-type: none;
      display: grid;
      grid-template-columns: 60px 1fr;
      margin-bottom: 16px;
      .badge-row {
        padding-top: 4px;
      }
      &:last-child {
        padding-bottom: 64px;
      }
    }
  }
  .title {
    padding: 12px 12px 0px;
    display: flex;
    justify-content: space-between;
    h3 {
      width: 100%;
      border-bottom: 1px solid var(--color-greyscale-100);
    }
    * {
      font-size: 26px;
    }
  }
  .close-icon * {
    font-size: 20px !important;
  }
}
.modal-backdrop {
  content: '';
  position: fixed;
  background: rgb(0 0 0 / 50%);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}
.benefit-modal-container {
  overflow: hidden !important;
  height: 90vh;

  .benefit-modal {
    .title {
      font-size: rem(24px);
    }
    .body {
      overflow-y: auto;
      height: 66vh;

      .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90%;
      }
    }
  }
}
